<template>
  <b-row>
    <b-col cols="12" md="3" v-for="(template, tk) in templates" :key="`template_${tk}`">
      <router-link :to="{ name: `${route_type}-ai-generate-template`, params: { slug: template.slug } }">
        <b-card class="card-designs h-20vh">
          <div class="d-flex justify-content-between">
            <div>
              <b-card-header class="py-0 pl-1">
                <b-card-title class="text-uppercase fs-1rem">
                  {{ template.label }} 
                </b-card-title>
              </b-card-header>
              <b-card-body class="py-0 pl-1 mt-1 text-muted">
                <h4 :title="template.description" class="fs-p875rem">{{ template.short_desc }}</h4>
              </b-card-body>
            </div>
            <div>
              <b-img v-if="!isEmpty(template.icon)" :src="`${$apiUrl}${template.icon}`" width="32" />
            </div>
          </div>

        </b-card>
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import { isEmpty } from '@/utilities';
export default {
  name: "GrouppedTemplates",
  props: ['templates'],
  computed: {
    route_type: {
      get() {
        let { type } = this.$route.meta;
        if (isEmpty(type)) {
          type = 'user'
        }
        return type;
      }
    }
  },
  methods: {
    isEmpty
  }
}
</script>